<template>
  <BasePageSection bg-color="#fadda8" full-bleed-bg>
    <VLayout row py-4 wrap>
      <VFlex xs12 sm7 md7 xl4 offset-xl2 pa-4 class="bloak-blue--text">
        <BasePageContent
          content-key="contact_form_title"
          default-content="Send a Message"
          tag-class="header-4 form-greeting pb-4 mb-4"
          tag="h2"
        />

        <BasePageContent
          content-key="contact_form_description"
          default-content="For Publicity Inquiries, please contact Gia Vecchio at Gia@foxglovecommunications.com!"
          tag="p"
          class="contact-form-desc"
        />
      </VFlex>
      <VFlex xs12 sm5 xl4 pa-4>
        <ContactForm
          class="bloak-contact-form"
          field-color="bloak-blue"
          :message-component="customMsgComponent"
        >
          <template #success-msg="{ dismiss }">
            <h1 class="grey--text">Thank you.</h1>
            <p class="r-headline">We'll get back to you soon.</p>
            <BaseButton class="ml-0" color="bloak-blue" small @click="dismiss"
              >Great!</BaseButton
            >
          </template>
          <template #submit-btn="{ sending }">
            <BaseButton
              color="bloak-blue"
              class="ml-0"
              type="submit"
              :loading="sending"
              >Submit</BaseButton
            >
          </template>
        </ContactForm>
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
import ContactForm from '@components/ContactForm'
import { VTextarea } from '@vuetify'
export default {
  name: 'ContactSection',
  components: {
    ContactForm,
    // eslint-disable-next-line
    VTextarea,
  },
  data() {
    return {
      customMsgComponent: VTextarea,
    }
  },
}
</script>

<style lang="scss">
@import '@design';
@import '@styleMixins';

.contact-form-desc {
  @extend .archivo--font;

  @include font-size(28px);
}

.bloak-contact-form {
  .v-text-field .v-input__control .v-input__slot {
    input {
      max-height: 2.5rem;
      font-size: 1.3rem;
      letter-spacing: 0.029em;
    }
    .v-label:not(.v-label--active) {
      height: 1.4rem;
      font-size: 1.3rem;
      color: black;
    }
    &::before,
    &::after {
      border-color: white;
      transform: scaleX(1);
    }
  }
}
</style>
